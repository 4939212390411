import { useTranslation } from '@ubique-innovation/react-translations';
import filter from 'lodash-es/filter';
import includes from 'lodash-es/includes';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import orderBy from 'lodash-es/orderBy';

import { map } from 'lodash-es';
import { Company, CompanyResponse } from '../../types/Company';
import { WorkspaceType } from '../../types/Workspace';
import LoadingIndicator from '../LoadingIndicator';
import FormControls from '../templates/FormControls';
import SectionTitle from '../templates/SectionTitle';
import Select, { SelectOption } from '../templates/Select';
import TextInput from '../templates/TextInput';
import * as styles from './WorkspaceTab.css';
import { loadCouponCreditCompanies } from '../../services/SuperDataService';

const emptyWorkspaceType: WorkspaceType = {
    bookingMail: '',
    googlePlacesId: '',
    companyId: 0,
    workspaceTitle: '',
};

const WorkspaceTab = (): React.ReactElement => {
    const { t } = useTranslation();
    const API = process.env.REACT_APP_API_URL || '';
    const [valid, setValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');
    const [companies, setCompanies] = useState<Company[]>();

    const getCompanyArray = (): SelectOption[] =>
        orderBy(
            map(companies, (c) => ({
                value: c.name,
                id: c.companyId,
            })),
            'value',
        );

    const [option, setOption] = useState<SelectOption[]>();
    const [formState, setFormState] = useState(emptyWorkspaceType);
    const auth = useAuth();

    useEffect(() => {
        setLoading(true);

        // noinspection JSIgnoredPromiseFromCall
        loadCouponCreditCompanies<CompanyResponse, Company[]>(auth, ({ companies: newCompanies }) => newCompanies).then(
            (newCompanies) => {
                setCompanies(orderBy(newCompanies, (e) => e.name));
                setLoading(false);
            },
        );
    }, [auth]);

    const onSave = useCallback(
        (space: WorkspaceType) => {
            setLoading(true);
            const params = new URLSearchParams({
                googlePlacesId: space.googlePlacesId,
                bookingMail: space.bookingMail,
                companyId: space.companyId.toString(),
                workspaceTitle: space.workspaceTitle,
            });
            fetch(`${API}/v1/super/workspaces/create?${params}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            }).then((response) => {
                if (response.ok) {
                    setLoading(false);
                    setFormState({
                        bookingMail: '',
                        googlePlacesId: '',
                        companyId: 0,
                        workspaceTitle: '',
                    });
                    return response.json();
                }
                alert(t('workspace.error'));
                setLoading(false);
                return Promise.reject();
            });
        },
        [auth.user?.access_token, API, t],
    );

    const handleSubmit = (): void => {
        if (!valid) {
            alert('Nicht gültig');
            return;
        }
        onSave(formState);
    };

    const validateForm = (f: WorkspaceType): boolean =>
        !(f.googlePlacesId === '' || f.bookingMail === '' || f.companyId === 0);

    const getCompanyString = (id: number): string => {
        if (id) {
            const comp = filter(companies, (l) => l.companyId === id);
            setText(comp[0].name);
            return comp[0].name;
        }
        return '';
    };

    useEffect(() => {
        setValid(validateForm(formState));
    }, [formState]);

    const workspaceForm = (
        <form id="workspaces" className={styles.inputForm} onSubmit={handleSubmit}>
            <div className={styles.workspaceTitle}>{t('workspaces.add.title')}</div>
            <SectionTitle title={t('workspaces.input.title')} />
            <TextInput
                value={formState.workspaceTitle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const w = { ...formState };
                    w.workspaceTitle = e.target.value;
                    setFormState(w);
                }}
            />
            <SectionTitle title={t('workspaces.google.places.id')} required />
            <TextInput
                value={formState.googlePlacesId}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const google = { ...formState };
                    google.googlePlacesId = e.target.value;
                    setFormState(google);
                }}
            />
            <SectionTitle title={t('workspaces.bookingMail')} required />
            <TextInput
                value={formState.bookingMail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const contact = { ...formState };
                    contact.bookingMail = e.target.value;
                    setFormState(contact);
                }}
            />
            <SectionTitle title={t('super.workspaces.companies')} required />
            <Select
                options={option || getCompanyArray()}
                value={text}
                onChange={(v: number) => {
                    const id = { ...formState };
                    id.companyId = v;
                    setFormState(id);
                    getCompanyString(v);
                }}
                inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let val = e.target.value;
                    if (e.target.value.slice(0, -1) === getCompanyString(formState.companyId)) {
                        val = e.target.value.slice(-1);
                    }
                    setText(val);
                    const filtered = getCompanyArray().filter((l) => {
                        const valLow = val.toLowerCase();
                        const low = l.value.toLowerCase();
                        return includes(low, valLow);
                    });
                    setOption(filtered);
                }}
            />
        </form>
    );

    return (
        <>
            <div className={styles.wrapper}>{loading ? <LoadingIndicator /> : workspaceForm}</div>
            <FormControls id="workspaces" formValid={valid} />
        </>
    );
};

export default WorkspaceTab;
